.Button-container {
  padding: 12px 32px;
  color: var(--kargo-black);
  background: linear-gradient(var(--kargo-black) 50%,var(--kargo-white) 50%);
  background-size: 100% 200%;
  background-position-y: -100%;
  border: 1px solid var(--kargo-black);
  font-size: 20px;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  &:hover {
    color: var(--kargo-white);
    background-position-y: 0;
    border: 1px solid var(--kargo-white);
  }
}
.Button-dark {
  color: var(--kargo-white);
  background: linear-gradient(var(--kargo-white) 50%,var(--kargo-black) 50%);
  background-size: 100% 200%;
  background-position-y: -100%;
  border: 1px solid var(--kargo-white);
  &:hover {
    color: var(--kargo-black);
    background-position-y: 0;
    border: 1px solid var(--kargo-black);
  }
}
