@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

.Partners-container {
  @include pageContainer();
  @include maxWidth();
  padding-top: 0;
  min-height: inherit;
  gap: 50px;
  @media screen and (min-width: $view-desktop) {
    padding-top: 40px;
    gap: 80px;
  }
  .Partners-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 48px;
    @media screen and (min-width: $view-desktop) {
      gap: 60px;
    }
    .Partners-image {
      filter: grayscale(1);
      transition: all 0.2s;
      cursor: pointer;
      &:hover {
        // transform: scale(1.1);
        filter: none;
      }
    }
    .Partners-image-dark {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}