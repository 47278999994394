@import url("https://fonts.googleapis.com/css2?family=Anton&family=DM+Sans:opsz@9..40&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  background-color: #111111;
  /* ::selection {
    background-color: #8653c9;
    color: var(--kargo-grey);
  } */
}

ol,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: #111111;
  transition: 0.2s;
  &:hover {
    color: #737373;
  }
}
