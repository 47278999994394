@keyframes blink {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

.SplashScreen-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 3;
  // background-color: var(--kargo-black);
  background-color: #111111;
  .SplashScreen-content {
    animation: blink 0.7s infinite;
  }
}