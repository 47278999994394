@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

.Zones-container {
  display: flex;
  justify-content: center;
  background-color: var(--kargo-black);
  .Zones-content {
    @include pageContainer();
    @include maxWidth();
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    @media screen and (min-width: $view-desktop) {
      max-width: 800px;
    }
  }
  .Zones-pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
}