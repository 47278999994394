@import '../../styles/variables.scss';

.Tabs-container {
  display: flex;
  gap: 32px;
  font-family: 'Anton';
  user-select: none;
  @media screen and (min-width: $view-desktop) {
    font-size: 28px;
    letter-spacing: 2px;
  }
}
.Tabs-unselected {
  opacity: 0.5;
  cursor: pointer;
}
.Tabs-selected {
  padding-bottom: 4px;
  border-bottom: 2px solid;
  opacity: 1;
  cursor: inherit;
}