@import '../../styles/variables.scss';

@keyframes slideInFromTop {
  from {
    top: -100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.Menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $header-height;
  padding: 24px 12px;
  color: #FEFEFE;
  background-color: #111111;
  text-align: center;
  overflow-x: auto;
  z-index: 1;
  animation: slideInFromTop 0.2s;
  .Menu-content {
    display: grid;
    gap: 40px;
    .Menu-links {
      display: grid;
      gap: 40px;
      .Menu-number {
        font-size: 12px;
      }
      .Menu-link {
        font-size: 24px;
        font-family: 'Anton';
      }
    }
  }
}
