@mixin colors() {
  --kargo-black: #111111;
  --kargo-white: #FEFEFE;
  --kargo-grey: #F6F6F6;
  --kargo-lightgrey: #787878;
}

@mixin link() {
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    color: var(--kargo-lightgrey);
  }
}