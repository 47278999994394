@mixin spacings() {
  --max-width-tablet: 500px;
  --max-width-desktop: 1300px;
  // --page-container-mobile: 120px 16px;
  // --page-container-tablet: 120px 16px;
  // --page-container-desktop: 120px 16px;
}

@mixin pageContainer() {
  display: grid;
  min-height: 100vh;
  // padding: var(--page-container-mobile);
  padding: 120px 16px;
  gap: 24px;
  overflow-x: auto;
  // @media screen and (min-width:$view-tablet) {
  //   padding: var(--page-container-tablet);
  // }
  // @media screen and (min-width:$view-desktop) {
  //   padding: var(--page-container-desktop);
  // }
  // @media screen and (min-width: $view-desktop) {
  //   gap: 24px;
  // }
}

@mixin maxWidth() {
  margin: auto;
  @media screen and (min-width:$view-tablet) {
    max-width: var(--max-width-tablet);
  }
  @media screen and (min-width:$view-desktop) {
    max-width: var(--max-width-desktop);
  }
}