@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

@keyframes maskAnimation {
  0% {
    mask: linear-gradient(to right, transparent 20%, white 20%, white 80%, transparent 80%);
  }
  5% {
    mask: linear-gradient(to right, transparent 19%, white 19%, white 81%, transparent 81%);
  }
  10% {
    mask: linear-gradient(to right, transparent 18%, white 18%, white 82%, transparent 82%);
  }
  15% {
    mask: linear-gradient(to right, transparent 17%, white 17%, white 83%, transparent 83%);
  }
  20% {
    mask: linear-gradient(to right, transparent 16%, white 16%, white 84%, transparent 84%);
  }
  25% {
    mask: linear-gradient(to right, transparent 15%, white 15%, white 85%, transparent 85%);
  }
  30% {
    mask: linear-gradient(to right, transparent 14%, white 14%, white 86%, transparent 86%);
  }
  35% {
    mask: linear-gradient(to right, transparent 13%, white 13%, white 87%, transparent 87%);
  }
  40% {
    mask: linear-gradient(to right, transparent 12%, white 12%, white 88%, transparent 88%);
  }
  45% {
    mask: linear-gradient(to right, transparent 11%, white 11%, white 89%, transparent 89%);
  }
  50% {
    mask: linear-gradient(to right, transparent 10%, white 10%, white 90%, transparent 90%);
  }
  55% {
    mask: linear-gradient(to right, transparent 9%, white 9%, white 91%, transparent 91%);
  }
  60% {
    mask: linear-gradient(to right, transparent 8%, white 8%, white 92%, transparent 92%);
  }
  65% {
    mask: linear-gradient(to right, transparent 7%, white 7%, white 93%, transparent 93%);
  }
  70% {
    mask: linear-gradient(to right, transparent 6%, white 6%, white 94%, transparent 94%);
  }
  75% {
    mask: linear-gradient(to right, transparent 5%, white 5%, white 95%, transparent 95%);
  }
  80% {
    mask: linear-gradient(to right, transparent 4%, white 4%, white 96%, transparent 96%);
  }
  85% {
    mask: linear-gradient(to right, transparent 3%, white 3%, white 97%, transparent 97%);
  }
  90% {
    mask: linear-gradient(to right, transparent 2%, white 2%, white 98%, transparent 98%);
  }
  95% {
    mask: linear-gradient(to right, transparent 1%, white 1%, white 99%, transparent 99%);
  }
  100% {
    mask: linear-gradient(to right, transparent 0%, white 0%, white 100%, transparent 100%);
  }
}

.AboutUs-container {
  @include pageContainer();
  @include maxWidth();
  gap: 32px;
  .AboutUs-content {
    @media screen and (min-width: $view-desktop) {
      column-count: 2;
      column-gap: 16px;
    }
    .AboutUs-text {
      margin-bottom: 16px;
    }
  }
  .AboutUs-video-animation {
    animation: maskAnimation 0.8s;
  }
  .AboutUs-video {
    margin-top: -50px;
    padding: 48px 0;
    @media screen and (min-width: $view-desktop) {
      mask: linear-gradient(transparent 20%, white 20%, white 80%, transparent 80%);
      margin-top: -120px;
      padding: inherit;
    }
  }
  .AboutUs-contact {
    margin-top: -50px;
    @media screen and (min-width: $view-desktop) {
      margin-top: -200px;
      padding-top: 90px;
    }
  }
}
