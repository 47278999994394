@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

@keyframes slide-in-from-bottom {
  0% {
      position: relative;
      bottom: -100%;
      opacity: 0;
  }
  100% {
      position: relative;
      bottom: 0;
      opacity: 1;
  }
}

.HeroBanner-container {
  @include pageContainer();
  color: var(--kargo-white);
  background-color: var(--kargo-black);
  gap: 48px;
  .HeroBanner-main {
    @include maxWidth();
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    @media screen and (min-width: $view-tablet) {
      padding: 0 16px;
    }
    @media screen and (min-width: $view-desktop) {
      flex-direction: row;
      gap: 16px;
    }
    .HeroBanner-title {
      text-align: center;
      @media screen and (min-width: $view-desktop) {
        text-align: left;
        width: 50%;
      }
    }
    .HeroBanner-images {
      display: flex;
      gap: 4px;
      .HeroBanner-image-container {
        display: grid;
        gap: 4px;
      }
      .HeroBanner-image-animation-0 {
        animation: 1s slide-in-from-bottom ease-out;
      } 
      .HeroBanner-image-animation-1 {
        animation: 1.2s slide-in-from-bottom ease-out;
      }
    }
  }
  .HeroBanner-infos {
    @include maxWidth();
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    font-size: 14px;
    @media screen and (min-width: $view-desktop) {
      gap: 80px;
      font-size: inherit;
    }
    .HeroBanner-info {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 12px;
      @media screen and (min-width: $view-desktop) {
        width: auto;
        max-width: 320px;
        gap: 20px;
      }
      .HeroBanner-info-logo {
        width: auto;
        height: 28px;
        @media screen and (min-width: $view-desktop) {
          height: 50px;
        }
      }
    }
  }
}

