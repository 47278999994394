@import '../../styles/variables.scss';

.CardExplainer-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  background-color: var(--kargo-grey);
  @media screen and (min-width: $view-desktop) {  
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 48px 32px;
    min-height: 200px;
    gap: 24px;
  }
  .CardExplainer-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    font-family: 'Anton';
    @media screen and (min-width: $view-desktop) {
      width: 40%;
      font-size: 32px;
    }
  }
  .CardExplainer-text {
    @media screen and (min-width: $view-desktop) {
      width: 60%;
    }
  }
}