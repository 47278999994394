@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

.Services-container {
  @include pageContainer();
  @include maxWidth();
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .Services-content {
    @media screen and (min-width: $view-desktop) {
      max-width: 50%;
    }
  }
  .Services-cards {
    display: grid;
    gap: 24px;
    @media screen and (min-width: $view-desktop) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}