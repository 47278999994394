@import '../../styles/variables.scss';

.Title-container {
  display: grid;
  gap: 16px;
}

.Title-container-centered {
  text-align: center;
}

.Title-content {
  font-size: 28px;
  font-family: 'Anton';
  font-weight: normal;
  @media screen and (min-width: $view-desktop) {
    font-size: 50px;
  }
}

.Title-subtitle {

}
