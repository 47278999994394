@import '../../styles/spacings.scss';
@import '../../styles/variables.scss';

.Footer-container {
  color: var(--kargo-white);
  background-color: var(--kargo-black);
  font-size: 18px;
  .Footer-content {
    display: flex;
    justify-content: space-between;
    padding: 32px 16px;
    gap: 12px;
    @media screen and (min-width: $view-desktop) {
      @include maxWidth();
      padding: 0 16px;
    }
    @media screen and (min-width: $view-desktop) {
      justify-content: space-between;
      align-items: center;
      padding: 48px 16px;
    }
    .Footer-links {
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-size: 14px;
      @media screen and (min-width: $view-desktop) {
        flex-direction: row;
        gap: 24px;
        font-size: 18px;
      }
    }
    .Footer-socials {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 14px;
      @media screen and (min-width: $view-desktop) {
        font-size: 18px;
      }
    }
  }
}