@import './styles/colors.scss';
@import './styles/spacings.scss';
@import './styles/variables.scss';

.App-container {
  @include colors();
  @include spacings();  
  color: var(--kargo-black);
  background-color: var(--kargo-white);
  @media screen and (min-width:$view-desktop) {
    font-size: 20px;
  }
}
