@import './colors.scss';
@import './variables.scss';

.link {
  @include link();
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-only {
  @media screen and (min-width: $view-desktop) {
    display: none;
  }
}

.desktop-only {
  display: none;
  @media screen and (min-width: $view-desktop) {
    display: inherit;
  }
}